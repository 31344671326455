@font-face {
    font-family: "Espa Extended";
    src: url("../public/fonts/Espa-Extended.eot");
    src: url("../public/fonts/Espa-Extended.eot?#iefix") format("embedded-opentype"),
        url("../public/fonts/Espa-Extended.woff") format("woff"),
        url("../public/fonts/Espa-Extended.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: "Graphik Web";
    src: url("../public/fonts/Graphik-Regular-Web.eot");
    src: url("../public/fonts/Graphik-Regular-Web.eot?#iefix") format("embedded-opentype"),
        url("../public/fonts/Graphik-Regular-Web.woff") format("woff"),
        url("../public/fonts/Graphik-Regular-Web.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: "Graphik Web";
    src: url("../public/fonts/Graphik-Medium-Web.eot");
    src: url("../public/fonts/Graphik-Medium-Web.eot?#iefix") format("embedded-opentype"),
        url("../public/fonts/Graphik-Medium-Web.woff") format("woff"),
        url("../public/fonts/Graphik-Medium-Web.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: "Graphik Web";
    src: url("../public/fonts/Graphik-Semibold-Web.eot");
    src: url("../public/fonts/Graphik-Semibold-Web.eot?#iefix") format("embedded-opentype"),
        url("../public/fonts/Graphik-Semibold-Web.woff") format("woff"),
        url("../public/fonts/Graphik-Semibold-Web.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: "Caecilia Web";
    src: url("../public/fonts/Caecilia-Regular-Web.eot?#iefix");
    src: url("../public/fonts/Caecilia-Regular-Web.eot?#iefix") format("eot"),
        url("../public/fonts/Caecilia-Regular-Web.woff") format("woff"),
        url("../public/fonts/Caecilia-Regular-Web.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Caecilia Web";
    src: url("../public/fonts/Caecilia-RegularItalic-Web.eot?#iefix");
    src: url("../public/fonts/Caecilia-RegularItalic-Web.eot?#iefix") format("eot"),
        url("../public/fonts/Caecilia-RegularItalic-Web.woff") format("woff"),
        url("../public/fonts/Caecilia-RegularItalic-Web.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Caecilia Web";
    src: url("../public/fonts/Caecilia-Bold-Web.eot?#iefix");
    src: url("../public/fonts/Caecilia-Bold-Web.eot?#iefix") format("eot"),
        url("../public/fonts/Caecilia-Bold-Web.woff") format("woff"),
        url("../public/fonts/Caecilia-Bold-Web.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Caecilia Web";
    src: url("../public/fonts/CaeciliaLTStd-HeavyItalic.otf");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("../public/fonts/Inter-roman.var.woff2") format("woff2 supports variations"),
        url("../public/fonts/Inter-roman.var.woff2") format("woff2-variations");
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("../public/fonts/Inter-italic.var.woff2") format("woff2 supports variations"),
        url("../public/fonts/Inter-italic.var.woff2") format("woff2-variations");
    font-weight: 100 900;
    font-display: swap;
    font-style: italic;
}
